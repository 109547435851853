import React from 'react'

import styles from "./styles/Badge.module.scss"

const Badge = ({ preset, children, link }) => {
    return (
        <>
            {link
                ? <a href={link} target="_blank" rel="noreferrer" className={`${styles.badge} ${styles[preset]} ${styles.link}`}>{children}</a>
                : <div className={`${styles.badge} ${styles[preset]}`}>{children}</div>
            }
        </>
    )
}

Badge.defaultProps = {
    preset: "primary",
}

export default Badge