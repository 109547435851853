import React from 'react'
import Badge from '../Badge/Badge'

import github from "../../assets/github.png"
import link from "../../assets/link.png"

import styles from "./styles/Projects.module.scss"

const Card = ({ name, source_code, deployment, description, picture, deployment_status, skills }) => {
    return (
        <div className={styles.card} data-src="./">
            <a href={deployment} target="_blank" rel="noreferrer" className={styles.link} ><img src={picture} alt={name} /><h2>{name}</h2>
            </a >
            <p>{description}</p>
            <div className={styles.skills}>{skills.map(skill => <Badge>{skill}</Badge>)}</div>
            <div className={styles.footer}>
                <a href={source_code} target="_blank" rel="noreferrer">
                    <img src={github} alt="" />
                </a>
                {deployment && <a href={deployment} target="_blank" rel="noreferrer">
                    <img src={link} alt="" />
                </a>}
                <Badge preset={deployment_status === 'Live' ? 'success' : 'primary'}>{deployment_status}</Badge>
            </div>
        </div >
    )
}

export default Card