import React from 'react'

import styles from "./styles/Bio.module.scss"

import space from "../../assets/space.png"
import Badge from '../Badge/Badge'

const Bio = () => {
    return (
        <div className={styles.bio}>
            <div className={styles.section}>
                <Badge>software engineer</Badge>
                <h1>adrian hartley.</h1>
                <p>I’m currently working at Remo Health as a Software Engineer. I have 3 years of experience, and my passion is learning how to use my skills to help people.</p>
                <div className={styles.social}>
                    <Badge link="https://www.linkedin.com/in/hannah-adrian-hartley/" >LinkedIn</Badge>
                    <Badge link="https://github.com/meep-morp" >Github</Badge>
                    <Badge link="mailto:adrian.hartley.2000@gmail.com" >adrian.hartley.2000@gmail.com</Badge>
                </div>
            </div>
            <div><img src={space} alt="" draggable={false} /></div>
        </div>
    )
}

export default Bio