import Bio from "../components/Bio";
import AwesomeSlider from "react-awesome-slider";
import "../styles/general.scss";
import Card from "../components/Projects/Card";
import projects from "../data/projects.json";
import Footer from "../components/Footer";
import Navbar from "../components/navbar";

console.log();

export default function App() {
	return (
		<>
			<AwesomeSlider infinite mobileTouch fillParent>
				<div>
					<Bio />
				</div>
				<div className="typing" style={{ fontSize: "90px" }}>
					Projects
				</div>
				{/**   Map doesn't work with awesome slider elements yet  **/}
				<div>
					<Card {...projects[0]} />
				</div>
				<div>
					<Card {...projects[1]} />
				</div>
				<div>
					<Card {...projects[2]} />
				</div>
				<div>
					<Card {...projects[3]} />
				</div>
			</AwesomeSlider>
		</>
	);
}
